const translation = {
  label: {
    title: "MovieStop",
    home: "Home",
    movies: "Movies",
    search: "Search",
  },
  movies: {
    welcome: "Welcome to Movies",
  },
  home: {
    welcome: "Welcome to home page",
  },
};

export default { translation };

const translation = {
  label: {
    title: "Pelicula",
    movies: "movies_espanyol",
    search: "search_espanyol",
  },
};

export default {
  translation,
};

import React from "react";
import ReactDOM from "react-dom/client";

import App from "containers/App";

import "assets/scss/index.scss";
import "swiper/css";
import "i18n/root";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
